










































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import serviceStoreModule from "@/store/modules/service";
import productStoreModule from "@/store/modules/productInventory";
import membershipStoreModule from "@/store/modules/membership";
import { Business, Membership, Product, Role, Service } from "@/types";

const { mapActions: membershipActions } = createNamespacedHelpers("MEMBERSHIP");
const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE_LIST");

const { mapActions: productActions, mapGetters: productGetters } =
  createNamespacedHelpers("PRODUCT_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField0: HTMLInputElement;
        imageField1: HTMLInputElement;
        imageField2: HTMLInputElement;
        imageField3: HTMLInputElement;
        policyField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "MembershipForm",
  data: () => ({
    currency: "KES",
    images: [] as File[],
    name: "",
    description: "",
    priceRules: [(v: string) => !!v || "Price field is required"],
    nameRules: [(v: string) => !!v || "Name field is required"],
    descriptionRules: [],
    policy: undefined as undefined | File,
    addServiceDialog: false,
    addProductDialog: false,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      // { text: "Duration", value: "durationInMinutes" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    selectedServiceIds: [] as string[],
    selectedServices: [] as Service[],
    selectedProductIds: [] as string[],
    selectedProducts: [] as Product[],
    price: {
      weekly: 0,
      fortnightly: 0,
      monthly: 0,
      annually: 0,
      biannually: 0,
      lumpsum: 0,
    },
    weekly: false,
    fortnightly: false,
    monthly: false,
    annually: false,
    biannually: false,
    lumpsum: false,
    cancellationPolicyText: "",
    showWeeklyLimit: false,
    weeklyLimit: null as null | number,
    showMonthlyLimit: false,
    monthlyLimit: null as null | number,
    showYearlyLimit: false,
    yearlyLimit: null as null | number,
    durations: ["Weeks", "Months", "Years"],
    period: 1,
    duration: "Weeks",
    durationRules: [(v: string) => !!v || "This field is required"],
    minPeriod: 1,
    maxPeriod: 4,
  }),
  watch: {
    role: "fetchServices",
    options: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },
    duration() {
      if (this.duration === "Weeks") {
        this.minPeriod = 1;
        this.maxPeriod = 4;
      } else if (this.duration === "Months") {
        this.minPeriod = 1;
        this.maxPeriod = 12;
      } else if (this.duration === "Years") {
        this.minPeriod = 1;
        this.maxPeriod = 99;
      }
    },
  },
  created() {
    this.fetchServices();
  },
  computed: {
    ...serviceGetters(["servicePage"]),
    ...productGetters(["productPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    periodRules() {
      return [
        (v: number) => !!v || "Period field is required",
        (v: number) =>
          v <= this.maxPeriod ||
          `Period should not exceed ${this.maxPeriod} ${this.duration}`,
        (v: number) =>
          v >= this.minPeriod ||
          `Period should not be below ${this.minPeriod} ${this.duration}`,
      ];
    },
  },
  methods: {
    ...membershipActions([
      "createMembership",
      "updateMembership",
      "uploadMembershipImages",
    ]),
    ...serviceActions(["fetchServiceList"]),
    ...productActions(["fetchProductList"]),
    validateMembership() {
      const valid = (
        this.$refs.membershipForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const usageLimits: Membership["usageLimits"] = [];

      if (this.showWeeklyLimit && this.weeklyLimit !== null) {
        usageLimits.push({
          limit: this.weeklyLimit,
          period: "week",
        });
      }

      if (this.showMonthlyLimit && this.monthlyLimit !== null) {
        usageLimits.push({
          limit: this.monthlyLimit,
          period: "month",
        });
      }

      if (this.showYearlyLimit && this.yearlyLimit !== null) {
        usageLimits.push({
          limit: this.yearlyLimit,
          period: "year",
        });
      }

      const payload = {
        name: this.name,
        description: this.description,
        businessId: (this.role.business as Business)._id,
        price: this.price,
        duration: {
          duration: this.period,
          duration_type: this.duration.toLowerCase(),
        },
        serviceIds: this.selectedServiceIds,
        productIds: this.selectedProductIds,
        cancellationPolicyText: this.cancellationPolicyText,
        usageLimits: usageLimits.length ? usageLimits : undefined,
      };

      if (!payload.usageLimits) delete payload.usageLimits;

      this.createMembership(payload).then((membership) => {
        if (membership) {
          let p1, p2;
          if (this.policy) {
            const data = new FormData();
            data.append("file", this.policy as File);
            p1 = this.updateMembership({
              id: `${membership._id}/cancellation-policy`, // TODO: fix this to real id
              membership: data,
            });
          }

          if (this.images.length) {
            const formData = new FormData();
            this.images.map((i) => {
              formData.append("files", i);
            });

            p2 = this.uploadMembershipImages({
              id: membership._id,
              membership: formData,
            });
          }

          Promise.all([p1, p2]).then((ep) => {
            if (ep) this.$router.push("/membership");
          });
        }
      });
    },
    upload(files: FileList) {
      Object.values(files).map((file) => this.images.push(file));
    },
    fetchServices() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const params = `?businessId=${bid}&page=${this.options.page || 1}`;
        this.fetchServiceList(params);
        this.fetchProductList(params);
      }
    },
    selectService() {
      this.selectedServices = this.servicePage.docs.filter((s: Service) =>
        this.selectedServiceIds.includes(s._id)
      );
    },
    selectProduct() {
      this.selectedProducts = this.productPage.docs.filter((s: Product) =>
        this.selectedProductIds.includes(s._id)
      );
    },
    removeService(service: Service) {
      let indx = -1;
      this.selectedServices.map((s, i) => {
        if (s._id === service._id) indx = i;
      });

      this.selectedServices.splice(indx, 1);
    },
    removeProduct(product: Product) {
      let indx = -1;
      this.selectedProducts.map((s, i) => {
        if (s._id === product._id) indx = i;
      });

      this.selectedProducts.splice(indx, 1);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SERVICE_LIST")) {
      this.$store.registerModule("SERVICE_LIST", serviceStoreModule);
    }
    if (!this.$store.hasModule("PRODUCT_LIST")) {
      this.$store.registerModule("PRODUCT_LIST", productStoreModule);
    }
    if (!this.$store.hasModule("MEMBERSHIP")) {
      this.$store.registerModule("MEMBERSHIP", membershipStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_LIST");
    this.$store.unregisterModule("MEMBERSHIP");
  },
});
